// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-company-contact-js": () => import("./../../../src/pages/company/contact.js" /* webpackChunkName: "component---src-pages-company-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-password-forgot-js": () => import("./../../../src/pages/password/forgot.js" /* webpackChunkName: "component---src-pages-password-forgot-js" */),
  "component---src-pages-platform-demo-js": () => import("./../../../src/pages/platform/demo.js" /* webpackChunkName: "component---src-pages-platform-demo-js" */),
  "component---src-pages-platform-demo-thank-you-js": () => import("./../../../src/pages/platform/demo/thank-you.js" /* webpackChunkName: "component---src-pages-platform-demo-thank-you-js" */),
  "component---src-pages-platform-js": () => import("./../../../src/pages/platform.js" /* webpackChunkName: "component---src-pages-platform-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-templates-singletons-js": () => import("./../../../src/templates/singletons.js" /* webpackChunkName: "component---src-templates-singletons-js" */)
}

